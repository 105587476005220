.gradient-background {
  background: linear-gradient(to bottom, #142247, #124782);
  color: white; /* Ensure text is readable */
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adjust padding as needed */
}

.styled-image {
  border: 5px solid white; /* Add a border to make the image stand out */
  border-radius: 10px; /* Optional: rounded corners */
  max-width: 100%; /* Ensure responsiveness */
  height: auto; /* Ensure responsiveness */
}

.styled-button {
  background-color: #142247; /* Popular green color */
  border-color: #142247; /* Button border color */
  color: white; /* Button text color */
}

.styled-button:hover {
  background-color: #218838; /* Darker green for hover */
  border-color: #218838; /* Darker green border on hover */
}

.countdown-col {
  background-color: #142247; /* Popular orange color */
  color: white; /* Ensure text is readable */
  padding: 10px; /* Adjust padding as needed */
  border-radius: 10px; /* Rounded corners */
  text-align: center;

  margin-bottom: 10px;
}

.countdown-timer {
  font-family: "Courier New", monospace; /* Mono font for countdown */
  font-size: 2rem; /* Adjust font size as needed */
  font-weight: bold; /* Bold font weight */
}

.countdown-label {
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: 600; /* Semi-bold font weight */
}

.title {
  font-size: 2.5rem; /* Adjust title font size */
  font-weight: bold; /* Bold font weight */
}

.subtitle {
  font-size: 1.75rem; /* Adjust subtitle font size */
  font-weight: 600; /* Semi-bold font weight */
}

@media (max-width: 576px) {
  .title {
    font-size: 1.75rem; /* Smaller font size for small screens */
  }

  .subtitle {
    font-size: 1.25rem; /* Smaller font size for small screens */
  }

  .countdown-timer {
    font-size: 1.5rem; /* Smaller font size for countdown on small screens */
  }

  .countdown-label {
    font-size: 0.75rem; /* Smaller font size for label on small screens */
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 3rem; /* Larger font size for medium and larger screens */
  }

  .subtitle {
    font-size: 2rem; /* Larger font size for medium and larger screens */
  }

  .countdown-timer {
    font-size: 2.5rem; /* Larger font size for countdown on medium and larger screens */
  }

  .countdown-label {
    font-size: 1.25rem; /* Larger font size for label on medium and larger screens */
  }
}
