.banner-style {
  background-image: url("https://i.ibb.co/SvK1TWC/Rozweb-UK-Limited-Banner-2.png");

  background-size: cover;
  max-width: 100%;
  height: auto;
  padding: 100px 0 100px 0;
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: black; 

}
@media only screen and (max-width: 600px) {
  .banner-style {
    padding: 30px 0 30px 0;
    color: white;
  }
}
